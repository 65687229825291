<template>
  <de-dropdown
    v-model="locale"
    :options="typedLocales"
    option-label="name"
    option-value="code"
    :placeholder="$t('form.chooseLanguage')"
    :panel-class="panelClass"
    append-to="self"
    popup-alignment="center"
    list-item-class="tw-p-0"
    type="chips"
    dropdown-icon-class="tw-w-300 tw-h-300"
  >
    <template #option="{ option }: { option: Locale }">
      <nuxt-link
        :to="switchLocalePath(option.code)"
        class="tw-inline-block tw-w-full tw-px-2 tw-py-1"
      >
        {{ option.name }}
      </nuxt-link>
    </template>
  </de-dropdown>
</template>

<script setup lang="ts">
import { useSwitchLocalePath } from '#i18n';
import { useI18n } from 'vue-i18n';
import DeDropdown from '~/shared/components/lib/dropdown/DeDropdown.vue';
import { typedLocales } from '~/data/domain/app.locale';
import type { Locale } from '~/data/domain/app.locale';

withDefaults(
  defineProps<{
    panelClass?: string;
  }>(),
  {
    panelClass: 'tw-w-full',
  },
);

const { locale } = useI18n();

const switchLocalePath = useSwitchLocalePath();
</script>
